import { render, staticRenderFns } from "./pc.vue?vue&type=template&id=574b4256&scoped=true"
import script from "./pc.vue?vue&type=script&lang=js"
export * from "./pc.vue?vue&type=script&lang=js"
import style0 from "./pc.vue?vue&type=style&index=0&id=574b4256&prod&scoped=true&lang=scss"
import style1 from "./pc.vue?vue&type=style&index=1&id=574b4256&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574b4256",
  null
  
)

export default component.exports